/** @jsx jsx */
import { jsx } from 'theme-ui'

import { TimelineEvent } from './TimelineEvent'

import { Row, Col, H2, Container } from 'gatsby-theme-landmarks-core'

export const TimelineSimple = props => {
  const {
    title,
    events,
    doneColor = 'tertiary',
    todoColor = 'mediumGrayOpaque'
  } = props

  return (
    <Row>
      <Col>
        <H2
          variant="golden"
          sx={{
            position: ['initial', null, 'sticky'],
            pb: [5, null, 0],
            top: '200px',
            mt: 0,
          }}
        >
          {title}
        </H2>
      </Col>
      <Col
        sx={{
          flex: ['auto', 'auto', 2]
        }}
      >
        <section className={props.className}>
          <Container sx={{ maxWidth: ['auto', '500px'] }}>
            {events.map((event, index, all) => (
            <TimelineEvent
              {...event}
              {...{
                doneColor,
                todoColor,
                index,
                all: all.length
              }}
            />
            ))}
          </Container>
        </section>
      </Col>
    </Row>
  )
}
