/** @jsx jsx */
import { jsx } from 'theme-ui'

export const Diamond = props => {
  const {
    className,
    color = 'tertiary',
  } = props

  return (
    <div
      className={className}
      sx={{
        position: 'relative',
        width: '36px',
        height: '36px',
        textAlign: 'center',
      }}
    >
      <div
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          backgroundColor: color,
          transform: 'rotate',
        }}
      />
      <span
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          lineHeight: '37px',
          textAlign: 'center',
          color: 'white',
        }}
      >
        {props.nr}
      </span>
    </div>
  )

}

