/** @jsx jsx */
import { useState, useEffect } from "react"
import { jsx, Button } from "theme-ui"
import LogoHome from "../components/Logos/LogoHome"

import "../../static/custom.css"

import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import {
  Helmet,
  Row,
  Col,
  Card,
  Container,
  H1,
  H2,
  H3,
  Grid,
  Small,
  Strong,
  Plx,
} from "gatsby-theme-landmarks-core"

import { TimelineSimple } from "../TimelineSimple/TimelineSimple"
import Header from "../sections/Header/Header"
import Footer from "../sections/Footer/Footer"
import { FloorSelector } from "../components/FloorSelector/FloorSelector"

const ScalaDesktopPage = (props) => {
  const { data } = props
  const [activeImage, setActiveImage] = useState(0)
  const {
    hartaDesktop,
    penthouse1,
    scalaCetate,
    etajretras,
    ansamblunouscala,
    Apartament28,
    Apartament15,
    Apartament24,
    Apartament26,
    icons01,
    icons02,
    icons03,
    icons04,
    icons05,
    icons06,
  } = data

  useEffect(() => {
    setTimeout(() => {
      const newIndex = activeImage === 1 ? 0 : activeImage + 1
      console.log(newIndex)
      setActiveImage(newIndex)
    }, 5000)
  }, [activeImage])

  return (
    <div>
      <Helmet>
        <meta
          name="title"
          content="Scala Frunzisului | Ansamblu rezidential pe strada Frunzisului"
        />
        <meta
          name="description"
          content="Apartamente noi de vanzare direct de la constructor, cu 1-3 camere si terasa. 🏡 Ansamblu rezidential in Cluj-Napoca. Vezi Apartamente!"
        />
        <title>
          Scala Frunzisului | Ansamblu rezidential pe strada Frunzisului, Cluj
          Napoca
        </title>
      </Helmet>
      <Header logoAlt="Scala Frunzisului Cluj Ansamblu Rezidential apartamente noi" />
      <div
        sx={{
          backgroundColor: "white",
          py: 3,
        }}
      >
        <div
          sx={{
            mb: 5,
            background: "#222",
            position: "relative",
          }}
        >
          <div
            sx={{
              height: ["50vh", "100vh"],
              position: "relative",
            }}
          >
            <Img
              fluid={penthouse1.childImageSharp.fluid}
              className={activeImage === 0 ? "fadeIn" : "fadeOut"}
              sx={{ height: "100%" }}
            />
            <Img
              fluid={scalaCetate.childImageSharp.fluid}
              className={activeImage === 1 ? "fadeIn" : "fadeOut"}
              sx={{ height: "100%" }}
            />
          </div>
          {activeImage === 0 && (
            <div
              sx={{
                position: ["relative", "absolute"],
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                zIndex: 2,
                display: "flex",
                padding: 3,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <H2
                sx={{
                  color: "white",
                  fontSize: [5, 6],
                  textShadow: ["none", "0 0 10px rgba(0,0,0, .3)"],
                }}
              >
                Scala Frunzisului vândut în totalitate
              </H2>
            </div>
          )}
          {activeImage === 1 && (
            <div
              sx={{
                position: ["relative", "absolute"],
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                zIndex: 2,
                display: "flex",
                padding: 3,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <H2
                sx={{
                  color: "white",
                  fontSize: [5, 6],
                  textShadow: ["none", "0 0 10px rgba(0,0,0, .3)"],
                }}
              >
                Noul proiect Scala Cetate Oradea
              </H2>
              <H3
                sx={{
                  color: "white",
                  fontSize: [3, 4],
                  textShadow: ["none", "0 0 10px rgba(0,0,0, .3)"],
                }}
              >
                Apartamente premium în centrul orașului
              </H3>
              <Button
                as="a"
                href="https://www.scalacetate.ro"
                variant="primary"
                sx={{ fontWeight: "bold" }}
              >
                Vezi disponibilitate
              </Button>
            </div>
          )}
        </div>
        <Row>
          <div
            sx={{
              textAlign: "center",
              color: "primary",
            }}
          >
            <H1 sx={{ px: [1, "auto"] }}>Bun venit la Scala Frunzișului</H1>
            <p
              sx={{
                px: [2, 6],
                fontSize: "22px",
              }}
            >
              <b>
                Povestea SCALA continuă cu un nou proiect rezidenţial gândit pe
                aceleaşi principii solide de calitate şi standarde ridicate de
                locuire ce ne definesc. SCALA Frunzişului este amplasat la
                intersecţia dintre cartierele Zorilor şi Mănăştur, în cadrul
                unui ansamblu de 6 blocuri ce beneficiază de drum privat, spaţii
                verzi, parcări subterane sau supraterane şi spaţiu de joaca
                individual.
                <br></br>Are cel mai mare regim de înălţime din întreg
                ansamblul, 17 niveluri, ce beneficiază de panorame
                spectaculoase. Structura apartamentelor este de la 1 la 3 camere
                iar compartimentările sunt optimizate astfel încât să
                deservească toate funcţiunile necesare unor familii moderne:
                zone de zi generoase, suprafeţe vitrate ample şi terase
                spaţioase.
              </b>
            </p>
          </div>
        </Row>
        <Row
          sx={{
            pt: 5,
            ml: [0, 2, "200px"],
          }}
        >
          <Col sx={{ pb: 5 }}>
            <div>
              <div
                sx={{
                  backgroundColor: "primary",
                  textAlign: "center",
                  maxWidth: ["auto", "350px", "420px"],
                }}
              >
                <H3 sx={{ color: "white", py: 2, px: 4, mb: "1px" }}>
                  Apartamente 1 cameră
                </H3>
              </div>
              <div
                sx={{
                  color: "white",
                  py: 1,
                  fontSize: 4,
                  backgroundColor: "#ee5500",
                  textAlign: "center",
                  maxWidth: ["auto", "350px", "420px"],
                }}
              >
                Sold out
              </div>
            </div>
            <div
              sx={{
                width: ["auto", "350px", "420px"],
              }}
            >
              <Img fluid={Apartament28.childImageSharp.fluid} />
            </div>
          </Col>
          <Col sx={{ pb: 5 }}>
            <div>
              <div
                sx={{
                  backgroundColor: "primary",
                  textAlign: "center",
                  maxWidth: ["auto", "350px", "420px"],
                }}
              >
                <H3 sx={{ color: "white", py: 2, px: 4, mb: "1px" }}>
                  Apartamente 2 camere
                </H3>
              </div>
              <div
                sx={{
                  color: "white",
                  py: 1,
                  fontSize: 4,
                  backgroundColor: "#ee5500",
                  textAlign: "center",
                  maxWidth: ["auto", "350px", "420px"],
                }}
              >
                Sold out
              </div>
            </div>
            <div
              sx={{
                width: ["auto", "350px", "420px"],
              }}
            >
              <Img fluid={Apartament15.childImageSharp.fluid} />
            </div>
          </Col>
        </Row>
        <Row
          sx={{
            ml: [0, 2, "200px"],
          }}
        >
          <Col sx={{ pb: 5 }}>
            <div>
              <div
                sx={{
                  backgroundColor: "primary",
                  textAlign: "center",
                  maxWidth: ["auto", "350px", "420px"],
                }}
              >
                <H3 sx={{ color: "white", py: 2, px: 4, mb: "1px" }}>
                  Apartamente 3 camere Starter
                </H3>
              </div>
              <div
                sx={{
                  color: "white",
                  py: 1,
                  fontSize: 4,
                  backgroundColor: "#ee5500",
                  textAlign: "center",
                  maxWidth: ["auto", "350px", "420px"],
                }}
              >
                Sold out
              </div>
            </div>
            <div
              sx={{
                width: ["auto", "350px", "420px"],
              }}
            >
              <Img fluid={Apartament24.childImageSharp.fluid} />
            </div>
          </Col>
          <Col sx={{ pb: 5 }}>
            <div>
              <div
                sx={{
                  backgroundColor: "primary",
                  textAlign: "center",
                  maxWidth: ["auto", "350px", "420px"],
                }}
              >
                <H3 sx={{ color: "white", py: 2, px: 4, mb: "1px" }}>
                  Apartamente 3 camere Family
                </H3>
              </div>
              <div
                sx={{
                  color: "white",
                  py: 1,
                  fontSize: 4,
                  backgroundColor: "#ee5500",
                  textAlign: "center",
                  maxWidth: ["auto", "350px", "420px"],
                }}
              >
                Sold out
              </div>
            </div>
            <div
              sx={{
                width: ["auto", "350px", "420px"],
              }}
            >
              <Img fluid={Apartament26.childImageSharp.fluid} />
            </div>
          </Col>
        </Row>
      </div>

      <FloorSelector bg={ansamblunouscala} />

      <div
        sx={{
          pt: 5,
          background: "white",
        }}
      >
        <div
          sx={{
            textAlign: "center",
            color: "primary",
          }}
        >
          <H1 sx={{ px: [1, "auto"], color: "primary" }}>
            Motive să alegi Scala Frunzișului
          </H1>
        </div>
        <div>
          <Row
            sx={{
              textAlign: "center",
              color: "primary",
            }}
          >
            <Col width={[12 / 12, 4 / 12, 4 / 12]}>
              <div sx={{ textAlign: "center" }}>
                <Img
                  // loading="eager"
                  fluid={icons01.childImageSharp.fluid}
                  sx={{
                    // size: "20%",
                    width: ["30vw", "7vw"],
                    height: ["30vw", "7vw"],
                    m: "auto",
                  }}
                />
              </div>
              <p sx={{ px: [2, "auto"], fontSize: "28px", fontWeight: "bold" }}>
                CALITATEA CONSTRUCȚIEI
              </p>
              <p
                sx={{
                  px: [3, 5],
                  fontSize: "20px",
                  fontWeight: "bold",
                  pb: [5, "auto"],
                }}
              >
                Construim la standarde inalte de calitate cu materiale premium
                de la furnizori de renume mondial, astfel încât tu să
                beneficiezi de cel mai înalt grad de confort.
              </p>
            </Col>
            <Col width={[12 / 12, 4 / 12, 4 / 12]}>
              <div sx={{ textAlign: "center" }}>
                <Img
                  // loading="eager"
                  fluid={icons02.childImageSharp.fluid}
                  sx={{
                    // size: "20%",
                    width: ["30vw", "7vw"],
                    height: ["30vw", "7vw"],
                    m: "auto",
                  }}
                />
              </div>
              <p sx={{ px: [2, "auto"], fontSize: "28px", fontWeight: "bold" }}>
                COMPARTIMENTĂRI OPTIMIZATE
              </p>
              <p
                sx={{
                  px: [3, 5],
                  fontSize: "20px",
                  fontWeight: "bold",
                  pb: [5, "auto"],
                }}
              >
                Toate apartamentele beneficiază de suprafeţe generoase şi sunt
                compartimentate astfel încât să ofere o funcționalitate optimă
                și un echilibru ideal între zonele de zi și de noapte.
              </p>
            </Col>
            <Col width={[12 / 12, 4 / 12, 4 / 12]}>
              <div sx={{ textAlign: "center" }}>
                <Img
                  // loading="eager"
                  fluid={icons03.childImageSharp.fluid}
                  sx={{
                    // size: "20%",
                    width: ["30vw", "7vw"],
                    height: ["30vw", "7vw"],
                    m: "auto",
                  }}
                />
              </div>
              <p sx={{ px: [2, "auto"], fontSize: "28px", fontWeight: "bold" }}>
                PANORAME SPECTACULOASE
              </p>
              <p
                sx={{
                  px: [3, 5],
                  fontSize: "20px",
                  fontWeight: "bold",
                  pb: [5, "auto"],
                }}
              >
                Ansamblul nostru beneficiază de 3 laturi fără vecini și o
                orientare ideală, gândită ca toate apartamentele să beneficieze
                de lumină și de o priveliște spectaculoasă asupra orașului.
              </p>
            </Col>
          </Row>
          <Row
            sx={{
              textAlign: "center",
              color: "primary",
              pt: [0, 5],
            }}
          >
            <Col width={[12 / 12, 4 / 12, 4 / 12]}>
              <div sx={{ textAlign: "center" }}>
                <Img
                  // loading="eager"
                  fluid={icons04.childImageSharp.fluid}
                  sx={{
                    // size: "20%",
                    width: ["30vw", "7vw"],
                    height: ["30vw", "7vw"],
                    m: "auto",
                  }}
                />
              </div>
              <p sx={{ px: [2, "auto"], fontSize: "28px", fontWeight: "bold" }}>
                ACCES FACIL
              </p>
              <p
                sx={{
                  px: [3, 5],
                  fontSize: "20px",
                  fontWeight: "bold",
                  pb: [5, "auto"],
                }}
              >
                Ansamblul situat la 10 minute de centru dispune de un sens
                giratoriu propriu pentru a-ti oferi acces facil spre oraș si ,
                toate punctele de interes din proximitate (supermarket,
                transport public, școli, grădinițe).
              </p>
            </Col>
            <Col width={[12 / 12, 4 / 12, 4 / 12]}>
              <div sx={{ textAlign: "center" }}>
                <Img
                  // loading="eager"
                  fluid={icons05.childImageSharp.fluid}
                  sx={{
                    // size: "20%",
                    width: ["30vw", "7vw"],
                    height: ["30vw", "7vw"],
                    m: "auto",
                  }}
                />
              </div>
              <p sx={{ px: [2, "auto"], fontSize: "28px", fontWeight: "bold" }}>
                PARC ȘI SPAȚII VERZI
              </p>
              <p
                sx={{
                  px: [3, 5],
                  fontSize: "20px",
                  fontWeight: "bold",
                  pb: [5, "auto"],
                }}
              >
                Scala Frunzișului beneficiază de un loc de joacă privat pentru
                copii si de peste 6000mp zone verzi si spatii de promenadă,
                toate acestea contribuind la bunastarea și relaxarea familiilor
                ce locuiesc în ansamblu.
              </p>
            </Col>
            <Col width={[12 / 12, 4 / 12, 4 / 12]}>
              <div sx={{ textAlign: "center" }}>
                <Img
                  // loading="eager"
                  fluid={icons06.childImageSharp.fluid}
                  sx={{
                    // size: "20%",
                    width: ["30vw", "7vw"],
                    height: ["30vw", "7vw"],
                    m: "auto",
                  }}
                />
              </div>
              <p sx={{ px: [2, "auto"], fontSize: "28px", fontWeight: "bold" }}>
                SOLUȚII DE AMENAJARE
              </p>
              <p
                sx={{
                  px: [3, 5],
                  fontSize: "20px",
                  fontWeight: "bold",
                  pb: [5, "auto"],
                }}
              >
                Grupul{" "}
                <a
                  href="https://www.perpetuum.ro"
                  target="_blank"
                  sx={{ textDecoration: "none" }}
                >
                  PERPETUUM
                </a>{" "}
                cu o experiență de peste 25 ani în amenajări, vă este alături cu
                soluții complete de amenajări și preturi preferențiale la o gamă
                variată de finisaje, mobilier și decorațiuni.
              </p>
            </Col>
          </Row>
          <div sx={{ pb: 5, pt: 3, justifyContent: "center", display: "flex" }}>
            <div
              sx={{
                color: "white",
                py: 3,
                fontSize: "25px",
                backgroundColor: "#05a5b2",
                textAlign: "center",
                maxWidth: ["auto", "400px", "600px"],
                px: 4,
              }}
            >
              <Link
                to={"/despre"}
                sx={{
                  textDecoration: "none",
                  color: "white",
                }}
              >
                VEZI DETALII COMPLETE DESPRE PROIECT
              </Link>
            </div>
          </div>
        </div>
        <Row sx={{ mb: 5, position: "relative" }}>
          <Col
            sx={{
              width: ["100vw", "40vw", "15vw"],
              ml: ["", "", 4],
              // mt: 2,
            }}
          >
            <Img fluid={hartaDesktop.childImageSharp.fluid} />
            <br></br>
            <Img fluid={etajretras.childImageSharp.fluid} />
          </Col>

          <Col
            sx={{
              width: ["100vw", "44vw", "44vw"],
              backgroundColor: "primary",
              color: "secondary",
              flex: ["auto", "auto", 2],
              mr: [0, 0, 4],
              // mt: [0, 6],
              pl: [4, "0px", "0px"],
              pb: 4,
            }}
          >
            <H2
              sx={{
                pt: 4,
                pb: 2,
                pl: 4,
              }}
            >
              Plan de dezvoltare
            </H2>
            <TimelineSimple
              doneColor="tertiary"
              todoColor="mediumGrayOpaque"
              events={[
                {
                  title: "Deschidere santier",
                  date: "2019",
                  done: true,
                },
                {
                  title: "Finalizare structură, inclusiv zidării",
                  date: "Ianuarie 2021",
                  done: true,
                },
                {
                  title: "Finalizare faţade",
                  date: "Iulie 2021",
                  done: true,
                },
                {
                  title: "Amenajări exterioare şi predare posesie",
                  date: "Iulie - Decembrie 2021",
                  done: false,
                },
                {
                  title: "Predare carte funciara",
                  date: "Martie 2022",
                  done: false,
                },
              ]}
            />
          </Col>
        </Row>
      </div>
      <LogoHome />
      <Footer />
    </div>
  )
}

export const query = graphql`
  {
    hartaDesktop: file(name: { eq: "harta-scala" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    penthouse1: file(name: { eq: "penthouse1" }) {
      childImageSharp {
        fluid(maxWidth: 3000, maxHeight: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    scalaCetate: file(name: { eq: "scala-cetate-oradea" }) {
      childImageSharp {
        fluid(maxWidth: 3000, maxHeight: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    etajretras: file(name: { eq: "etaj-retras" }) {
      childImageSharp {
        fluid(maxWidth: 3000, maxHeight: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    ansamblunouscala: file(name: { eq: "ansamblu-nou-scala" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    Apartament28: file(name: { eq: "Apartament28" }) {
      childImageSharp {
        fluid(maxWidth: 420, maxHeight: 480, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    Apartament15: file(name: { eq: "Apartament15" }) {
      childImageSharp {
        fluid(maxWidth: 420, maxHeight: 480, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    Apartament24: file(name: { eq: "Apartament24" }) {
      childImageSharp {
        fluid(maxWidth: 420, maxHeight: 480, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    Apartament26: file(name: { eq: "Apartament26" }) {
      childImageSharp {
        fluid(maxWidth: 420, maxHeight: 480, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    icons01: file(name: { eq: "icons-01" }) {
      childImageSharp {
        fluid(maxWidth: 250, maxHeight: 250, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    icons02: file(name: { eq: "icons-02" }) {
      childImageSharp {
        fluid(maxWidth: 250, maxHeight: 250, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    icons03: file(name: { eq: "icons-03" }) {
      childImageSharp {
        fluid(maxWidth: 250, maxHeight: 250, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    icons04: file(name: { eq: "icons-04" }) {
      childImageSharp {
        fluid(maxWidth: 250, maxHeight: 250, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    icons05: file(name: { eq: "icons-05" }) {
      childImageSharp {
        fluid(maxWidth: 250, maxHeight: 250, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    icons06: file(name: { eq: "icons-06" }) {
      childImageSharp {
        fluid(maxWidth: 250, maxHeight: 250, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
  }
`
export default ScalaDesktopPage
