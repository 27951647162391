/** @jsx jsx */
import { jsx } from 'theme-ui'

import { useInView } from 'react-intersection-observer'

import { H4 } from '../../../gatsby-theme-landmarks-core/src/tags/tags'
import { Diamond } from '../Diamond/Diamond'

export const TimelineEvent = props => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  })
  const lastEvent = props.index === props.all - 1
  const eventStyle = props.done
    ? {
        color: props.doneColor,
        borderStyle: 'solid',
        borderLeftWidth: '3px'
      }
    : {
        color: props.todoColor,
        borderStyle: 'dashed',
        borderLeftWidth: '2px'
      }

  return (
    <div
      ref={ref}
      key={`timeline-${props.index}`}
      sx={{
        pl: [5, 6],
        position: 'relative',
        transitionProperty: 'all',
        transitionDuration: '.4s',
        transitionTimingFunction: 'ease-out',
        ...lastEvent
          ? {
            pb: 0,
            minHeight: 'auto',
          }
          : {
            pb: '80px',
            minHeight: '180px',
          },
        ...inView
          ? {
            transform: 'translateY(0)',
            opacity: 1
          }
          : {
            transform: 'translateY(100px)',
            opacity: 0
          }
      }}
    >
      <H4>{props.title}</H4>
      {props.text && <p>{props.text}</p>}
      <span>{props.date}</span>
      {!lastEvent && (
        <div
          sx={{
            position: 'absolute',
            top: '10px',
            left: '16px',
            height: '100%',
            transitionProperty: 'all',
            transitionDuration: '1s',
            transitionTimingFunction: 'ease-out',
            borderRightWidth: 0,
            ...eventStyle,
            ...inView
              ? {
                opacity: 1,
                height: '100%',
              }
              : {
                opacity: 0,
                height: '0px',
              },
          }}
        />
      )}
      <Diamond
        nr={props.index + 1}
        color={eventStyle.color}
        sx={{
          position: 'absolute',
          left: 0,
          top: 0
        }}
      />
    </div>
  )
}
