/** @jsx jsx */
import { jsx, Box } from "theme-ui";

import React, { useState } from "react";
import { Floorplan } from "../../templates/Floorplan";
import Img from 'gatsby-image';

import { Row, Col, useWindowSize, Button } from "gatsby-theme-landmarks-core";
import { navigate } from "gatsby";

export const FloorSelector = (props) => {
  const { bg } = props;

  const floors = [
    <path
      id="etaj-RETRAS"
      d="M 760.50,272.25
      C 760.50,272.25 760.73,223.45 760.73,223.45
        760.73,223.45 1176.36,222.91 1176.36,222.91
        1176.36,222.91 1176.50,272.25 1176.50,272.25"
      transform="translate(1031 120)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-14"
      d="M 1176.44,272.81
      C 1176.44,272.81 1176.36,335.27 1176.36,335.27
        1176.36,335.27 760.56,335.50 760.56,335.50
        760.56,335.50 760.44,272.69 760.44,272.69"
      transform="translate(1031 125)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-13"
      d="M 760.58,335.63
      C 760.58,335.63 760.59,390.20 760.59,390.20
        760.59,390.20 1176.22,390.17 1176.22,390.17
        1176.22,390.17 1176.27,335.51 1176.27,335.51"
      transform="translate(1031 125)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-12"
      d="M 760.61,390.35
      C 760.61,390.35 760.64,445.64 760.64,445.64
        760.64,445.64 1176.24,445.39 1176.24,445.39
        1176.24,445.39 1176.20,390.29 1176.20,390.29"
      transform="translate(1031 125)"
      fill="#fff"
      opacity="0.51"
    />,

    <path
      id="etaj-11"
      d="M 760.67,445.93
      C 760.67,445.93 760.65,501.57 760.65,501.57
        760.65,501.57 1176.24,501.49 1176.24,501.49
        1176.24,501.49 1176.22,445.62 1176.22,445.62"
      transform="translate(1031 123)"
      fill="#fff"
      opacity="0.51"
    />,

    <path
      id="etaj-10"
      d="M 760.66,501.77
      C 760.66,501.77 760.64,556.49 760.64,556.49
        760.64,556.49 1176.24,556.55 1176.24,556.55
        1176.24,556.55 1176.23,501.65 1176.23,501.65"
      transform="translate(1031 122)"
      fill="#fff"
      opacity="0.51"
    />,

    <path
      id="etaj-9"
      d="M 760.66,556.72
      C 760.69,556.72 760.69,611.98 760.69,611.98
        760.69,611.98 1176.20,611.98 1176.20,611.98
        1176.20,611.98 1176.23,556.62 1176.23,556.62"
      transform="translate(1031 120)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-8"
      H2
      d="M 760.72,612.22
      C 760.72,612.22 760.72,667.84 760.72,667.84
        760.72,667.84 1176.17,667.84 1176.17,667.84
        1176.17,667.84 1176.19,612.06 1176.19,612.06"
      transform="translate(1031 119)"
      fill="#fff"
      opacity="0.51"
    />,

    <path
      id="etaj-7"
      d=" M 760.73,668.04
      C 760.73,668.04 760.73,722.45 760.73,722.45
        760.73,722.45 1176.20,722.12 1176.20,722.12
        1176.20,722.12 1176.16,667.94 1176.16,667.94"
      transform="translate(1031 119)"
      fill="#fff"
      opacity="0.51"
    />,

    <path
      id="etaj-6"
      d="M 760.76,722.62
      C 760.76,722.62 760.76,777.44 760.76,777.44
        760.76,777.44 1176.20,777.47 1176.20,777.47
        1176.20,777.47 1176.19,722.27 1176.19,722.27"
      transform="translate(1031 119)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-5"
      d="M 760.73,777.64
      C 760.73,777.64 760.78,832.89 760.78,832.89
        760.78,832.89 1176.12,832.83 1176.12,832.83
        1176.12,832.83 1176.13,777.60 1176.13,777.60"
      transform="translate(1031 119)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-4"
      d="M 760.79,833.01
      C 760.79,833.01 760.78,888.60 760.78,888.60
        760.78,888.60 1176.07,888.64 1176.07,888.64
        1176.07,888.64 1176.04,832.93 1176.04,832.93"
      transform="translate(1031 119)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-3"
      d="M 760.78,888.84
      C 760.78,888.84 760.75,944.69 760.75,944.69
        760.75,944.69 1176.03,944.62 1176.03,944.62
        1176.03,944.62 1176.03,888.84 1176.03,888.84"
      transform="translate(1031 119)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-2"
      d="M 760.77,944.84
      C 760.77,944.84 760.72,994.34 760.72,994.34
        760.72,994.34 1176.02,994.42 1176.02,994.42
        1176.02,994.42 1176.03,944.78 1176.03,944.78"
      transform="translate(1031 119)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-1"
      d="M 1175.98,994.67
      C 1175.98,994.67 1175.92,1045.61 1175.92,1045.55
        1175.92,1045.48 760.68,1045.17 760.68,1045.17
        760.68,1045.17 760.73,994.53 760.73,994.53"
      transform="translate(1031 119)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-MEZANIN"
      d="M 1175.91,1045.60
      C 1175.91,1045.60 1175.91,1095.36 1175.91,1095.36
        1175.91,1095.36 760.62,1095.23 760.62,1095.23
        760.62,1095.23 760.69,1045.30 760.69,1045.30"
      transform="translate(1031 119)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-PARTER"
      d="M 1175.87,1095.47
      C 1175.87,1095.47 1175.89,1145.33 1175.89,1145.33
        1175.89,1145.33 760.62,1145.48 760.62,1145.48
        760.62,1145.48 760.62,1093.70 760.62,1093.70"
      transform="translate(1031 119)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-S1"
      d="M 880.21,1162.30
    C 880.21,1162.30 933.20,1162.34 933.20,1162.34
      933.20,1162.34 933.32,1215.32 933.32,1215.32
      933.32,1215.32 880.32,1215.19 880.32,1215.19"
      transform="translate(1031 119)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-S2"
      d="M 944.21,1162.30
  C 944.21,1162.30 997.20,1162.34 997.20,1162.34
    997.20,1162.34 997.32,1215.32 997.32,1215.32
    997.32,1215.32 944.32,1215.19 944.32,1215.19"
      transform="translate(1031 119)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-S3"
      d="M 1007.66,1162.30
  C 1007.66,1162.30 1060.66,1162.34 1060.66,1162.34
    1060.66,1162.34 1060.59,1215.32 1060.59,1215.32
    1060.59,1215.32 1007.59,1215.19 1007.59,1215.19"
      transform="translate(1031 119)"
      fill="#fff"
      opacity="0.51"
    />,
  ];

  const floorsMobile = [
    <path
      id="etaj-RETRAS"
      d="M 322.50,322.50
      C 322.50,322.50 322.50,387.00 322.50,387.00
        322.50,387.00 813.00,387.00 813.00,387.00
        813.00,387.00 813.00,325.50 813.00,325.50"
      transform="translate(988 -18)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-14"
      d="M 318.55,387.27
      C 318.55,387.27 318.75,460.31 318.75,460.31
        318.75,460.31 818.69,460.38 818.69,460.38
        818.69,460.38 818.55,387.64 818.55,387.64
        818.55,387.64 318.55,387.27 318.55,387.27 Z"
      transform="translate(988 -18)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-13"
      d="M 318.55,460.27
      C 318.55,460.27 318.50,525.62 318.50,525.62
        318.50,525.62 818.62,525.75 818.62,525.75
        818.62,525.75 818.55,460.64 818.55,460.64
        818.55,460.64 318.55,460.27 318.55,460.27 Z"
      transform="translate(988 -16)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-12"
      d="M 318.67,527.02
      C 318.67,527.02 318.62,592.38 318.62,592.38
        318.62,592.38 818.75,592.50 818.75,592.50
        818.75,592.50 818.67,527.39 818.67,527.39
        818.67,527.39 318.67,527.02 318.67,527.02 Z"
      transform="translate(988 -15)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-11"
      d="M 318.76,594.11
      C 318.76,594.11 318.72,659.47 318.72,659.47
        318.72,659.47 818.84,659.59 818.84,659.59
        818.84,659.59 818.76,594.48 818.76,594.48
        818.76,594.48 318.76,594.11 318.76,594.11 Z"
      transform="translate(988 -15)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-10"
      d="M 318.81,661.30
      C 318.81,661.30 318.76,726.65 318.76,726.65
        318.76,726.65 818.89,726.77 818.89,726.77
        818.89,726.77 818.81,661.66 818.81,661.66
        818.81,661.66 318.81,661.30 318.81,661.30 Z"
      transform="translate(988 -15)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-9"
      d="M 318.81,727.17
      C 318.81,727.17 318.76,792.52 318.76,792.52
        318.76,792.52 818.89,792.65 818.89,792.65
        818.89,792.65 818.81,727.53 818.81,727.53
        818.81,727.53 318.81,727.17 318.81,727.17 Z"
      transform="translate(988 -15)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-8"
      d="M 318.84,795.17
      C 318.84,795.17 318.80,860.52 318.80,860.52
        318.80,860.52 818.92,860.65 818.92,860.65
        818.92,860.65 818.84,795.53 818.84,795.53
        818.84,795.53 318.84,795.17 318.84,795.17 Z"
      transform="translate(988 -15)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-7"
      d="M 318.72,861.42
      C 318.72,861.42 318.67,926.77 318.67,926.77
        318.67,926.77 818.80,926.90 818.80,926.90
        818.80,926.90 818.72,861.78 818.72,861.78
        818.72,861.78 318.72,861.42 318.72,861.42 Z"
      transform="translate(988 -15)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-6"
      d="M 318.71,927.65
      C 318.71,927.65 318.66,993.00 318.66,993.00
        318.66,993.00 818.79,993.13 818.79,993.13
        818.79,993.13 818.71,928.01 818.71,928.01
        818.71,928.01 318.71,927.65 318.71,927.65 Z"
      transform="translate(988 -15)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-5"
      d="M 318.70,994.10
      C 318.70,994.10 318.65,1059.46 318.65,1059.46
        318.65,1059.46 818.78,1059.58 818.78,1059.58
        818.78,1059.58 818.70,994.47 818.70,994.47
        818.70,994.47 318.70,994.10 318.70,994.10 Z"
      transform="translate(988 -12)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-4"
      d="M 318.64,1060.26
      C 318.64,1060.26 318.59,1125.61 318.59,1125.61
        318.59,1125.61 818.72,1125.73 818.72,1125.73
        818.72,1125.73 818.64,1060.62 818.64,1060.62
        818.64,1060.62 318.64,1060.26 318.64,1060.26 Z"
      transform="translate(988 -10)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-3"
      d="M 318.59,1127.42
      C 318.59,1127.42 318.54,1192.78 318.54,1192.78
        318.54,1192.78 818.67,1192.90 818.67,1192.90
        818.67,1192.90 818.59,1127.79 818.59,1127.79
        818.59,1127.79 318.59,1127.42 318.59,1127.42 Z"
      transform="translate(988 -12)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-2"
      d="M 318.46,1193.42
      C 318.46,1193.42 318.42,1258.78 318.42,1258.78
        318.42,1258.78 818.54,1258.90 818.54,1258.90
        818.54,1258.90 818.46,1193.79 818.46,1193.79
        818.46,1193.79 318.46,1193.42 318.46,1193.42 Z"
      transform="translate(988 -15)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-1"
      d="M 318.34,1258.86
      C 318.34,1258.86 318.29,1324.21 318.29,1324.21
        318.29,1324.21 818.42,1324.34 818.42,1324.34
        818.42,1324.34 818.34,1259.23 818.34,1259.23
        818.34,1259.23 318.34,1258.86 318.34,1258.86 Z"
      transform="translate(988 -15)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-MEZANIN"
      d="M 318.27,1324.36
      C 318.27,1324.36 318.36,1382.73 318.36,1382.73
        318.36,1382.73 818.55,1382.45 818.55,1382.45
        818.55,1382.45 818.27,1324.72 818.27,1324.72
        818.27,1324.72 318.27,1324.36 318.27,1324.36 Z"
      transform="translate(988 -15)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-PARTER"
      d="M 318.27,1382.86
      C 318.27,1382.86 318.36,1441.23 318.36,1441.23
        318.36,1441.23 818.55,1440.95 818.55,1440.95
        818.55,1440.95 818.27,1383.22 818.27,1383.22
        818.27,1383.22 318.27,1382.86 318.27,1382.86 Z"
      transform="translate(988 -15)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-S1"
      d="M 461.75,1468.25
      C 461.75,1468.25 525.75,1468.00 525.75,1468.00
        525.75,1468.00 525.62,1531.00 525.62,1531.00
        525.62,1531.00 461.62,1531.06 461.62,1531.06"
      transform="translate(988 -15)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-S2"
      d="M 539.54,1467.75
      C 539.54,1467.75 603.54,1467.50 603.54,1467.50
        603.54,1467.50 603.42,1530.50 603.42,1530.50
        603.42,1530.50 539.42,1530.56 539.42,1530.56"
      transform="translate(988 -15)"
      fill="#fff"
      opacity="0.51"
    />,
    <path
      id="etaj-S3"
      d="M 616.80,1467.27
      C 616.80,1467.27 680.80,1467.02 680.80,1467.02
        680.80,1467.02 680.67,1530.02 680.67,1530.02
        680.67,1530.02 616.67,1530.08 616.67,1530.08"
      transform="translate(988 -15)"
      fill="#fff"
      opacity="0.51"
    />,
  ];
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 640;
  const [activeFloor, setActiveFloor] = useState("etaj-4");
  const [selectedFloor, setSelectedFloor] = useState("etaj-4");

  return (
    <div sx={{ position: 'relative' }}>

      {!isMobile ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1920 1280"
          sx={{
            position: 'relative',
            height: "auto",
            width: "auto",
            maxWidth: "100%",
          }}
        >
          <text
            x="826"
            y="160"
            dx="-30"
            font-family="Arial, Helvetica, sans-serif"
            fill="white"
            sx={{ fontSize: 6, color: "white", fontFamily: "Auto3 LightLF" }}
          >
            Selectează etajul
          </text>
          <g transform="translate(-1040 -88)">
            {floors.map((f, i) => {
              const active = activeFloor === f.props.id;
              const selected = selectedFloor === f.props.label;
              return React.cloneElement(f, {
                style: {
                  transition: "all .3s",
                  cursor: "pointer",
                  opacity: active ? 0.5 : 0,
                },
                onClick: () => navigate(`/apartamente-noi/${f.props.id}`),
                onMouseEnter: () => setActiveFloor(f.props.id),
              });
            })}
          </g>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1100 1600"
          sx={{
            position: 'relative',
            height: "auto",
            width: "auto",
            maxWidth: "100%",
            // zIndex: 2,
          }}
        >
          <text
            x="405"
            y="130"
            dx="-135"
            font-family="Arial, Helvetica, sans-serif"
            fill="white"
            sx={{
              fontSize: "80px",
              color: "white",
              fontFamily: "Auto3 LightLF",
            }}
          >
            Selectează etajul
          </text>
          <g transform="translate(-1008 24)">
            {floorsMobile.map((f, i) => {
              const active = activeFloor === f.props.id;
              const selected = selectedFloor === f.props.label;
              return React.cloneElement(f, {
                style: {
                  transition: "all .3s",
                  cursor: "pointer",
                  opacity: active ? 0.5 : 0,
                },
                onClick: () => setActiveFloor(f.props.id),
              });
            })}
          </g>
        </svg>
      )}
      {isMobile && activeFloor && (
        <div
          sx={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "primary",
            p: 3,
          }}
        >
          <Button
            onClick={() => navigate(`/apartamente-noi/${activeFloor}`)}
            sx={{ m: 0 }}
          >
            VEZI {activeFloor.includes("S1") ? activeFloor.replace("etaj-", "SUBSOL ").toUpperCase() : (activeFloor.includes("S2") ? activeFloor.replace("etaj-", "SUBSOL ").toUpperCase() : (activeFloor.includes("S3") ? activeFloor.replace("etaj-", "SUBSOL ").toUpperCase() : activeFloor.replace("-", " ").toUpperCase()))}
          </Button>
        </div>
      )}

      <div sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        zIndex: -1,
        bottom: isMobile ? '80px' : 0,
      }}>
        <Img fluid={bg.childImageSharp.fluid} sx={{ height: '100%' }} />
      </div>
    </div>
  );
};
